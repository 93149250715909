<template>
	<div :style="'background: ' + bootbakcolor" class="footer">
	  <div v-if="tabBarList3.length > 0" class="footer-wrapper">
		<div class="w1200">
		  <div v-if="true" class="top flex-bt">
			<div
			  class="items flex-center"
			  v-for="(item, index) in tabBarList3"
			  :key="index"
			>
			  <div @click="openPopup(index)"><img class="icon" :src="item.icon" /></div>
			  <span @click="openPopup(index)">{{ item.label }}</span>
			</div>
		  </div>
		</div>
	  </div>
	  <div class="container">
		<div class="row">
			<!-- <div class="col-md-4" v-if="hasLinks"> -->
				<div class="col-md-4">
  <h3 class="fo-title">{{ $t("Link") }}</h3>
  <ul class="f1-list">
    <li>
		<a v-if="uniacid === 20 && locale === 'EN'" href="/#/ufterms-en" target="_blank">{{ $t('Terms and conditions') }}</a>
		<a v-if="uniacid === 20 && locale === 'zh-HK'" href="/#/ufterms" target="_blank">{{ $t('Terms and conditions') }}</a>
		<a v-if="uniacid === 17" href="/#/newmannterms" target="_blank">免責聲明</a><br>
      <a v-if="whatsapp" :href="'https://api.whatsapp.com/send?phone=' + whatsapp">Whatsapp</a>
      <br v-if="whatsapp" />
      <a v-if="facebook" :href="facebook">Facebook</a>
      <a v-if="uniacid === 15" href="https://alex.alexdental.info/xieyi/alexau.html" target="_blank">免責聲明</a>
	  <a v-if="uniacid === 29" href="https://sincere.sincereproperty.info/xieyi/sincere.html" target="_blank">免責聲明</a>
      <a v-if="uniacid === 19" href="https://ace.aceaviation.info/xieyi/ace.html" target="_blank">免責聲明</a>
	  <a v-if="uniacid === 24" href="https://sk.skeasy.info/xieyi/skeasy.html" target="_blank">免責聲明</a>
    </li>
  </ul>
</div>
		  <div class="col-md-4">
			<h3 class="fo-title">{{ $t("Open Time") }}</h3>
			<ul class="co-list">
			  <li v-for="item in opentime"><i class="fa fa-clock"></i>{{ item }}</li>
			</ul>
		  </div>
		  <div class="col-md-4">
			<h3 class="fo-title">{{ $t("Contact") }}</h3>
			<ul class="co-list">
			  <li>{{ qyname }}</li>
			  <li v-if="email">
				<i class="fa fa-envelope"></i>
				<a :href="'mailto:' + email">{{ email }}</a>
			  </li>
			  <li v-if="tel"><i class="fa fa-phone"></i>{{ tel }}</li>
			  <li v-if="address"><i class="fa fa-map-marker"></i>{{ address }}</li>
			</ul>
		  </div>
		</div>
		<!-- <div class="row">
		  <div class="col-md-12">
			<div id="mc_embed_signup" v-if="uniacid === 17"></div>
			<div v-if="uniacid === 15">
			  <a href="https://alex.alexdental.info/xieyi/alexau.html" target="_blank">免責聲明</a>
			</div>
			<div v-if="uniacid === 17">
			  <a href="https://nm.newmann.info/xieyi/DrNewmann.html" target="_blank">免責聲明</a>
			</div>
			<div v-if="uniacid === 19">
			  <a href="https://ace.aceaviation.info/xieyi/ace.html" target="_blank">免責聲明</a>
			</div>
			<div v-if="uniacid === 20">
			  <a href="/#/terms" target="_blank">條款及細則</a>
			</div>
			<a v-if="whatsapp" :href="'https://api.whatsapp.com/send?phone=' + whatsapp">Whatsapp</a><br v-if="whatsapp" />
			<a v-if="facebook" :href="facebook">Facebook</a>
		  </div>
		</div> -->
	  </div>
	  <div class="copy-right">
  <div class="container copyright-container">
    <p class="copyright-text">CopyRights @ All Rights Reserved by {{ qyname }}</p>
  </div>
</div>
	  <!-- Popup Iframe -->
	  <div v-if="iframeVisible" class="iframe-popup">
		<div class="iframe-content">
		  <button @click="closePopup" class="close-btn">X</button>
		  <iframe :src="iframeUrl" frameborder="0"></iframe>
		</div>
	  </div>
	</div>
  </template>
  
  <script>

  export default {
	name: "Footer",
	data() {
	  return {
		html: "",
		whatsapp: "",
		facebook: "",
		email: "",
		bootbakcolor: "",
		opentime: [],
		webset: [],
		id: "",
		uniacid: parseInt(window.localStorage.getItem("uniacid")),
		tabBarList3: [],
		iframeVisible: false,
		iframeUrl: "",
		tel: "loading",
		qyname: "",
		address: "loading",
		locale: this.$i18n.locale,
	  };
	},
	created() {
	  this.getAutoLink();
	},
	computed: {
		hasLinks() {
    return this.whatsapp || this.facebook || this.uniacid === 15 || this.uniacid === 17 || this.uniacid === 19 || this.uniacid === 20;
  }
},
    
	methods: {
	  openPopup(index) {
		if (this.uniacid == 26) {
			console.log("openPopup called with uniacid:", this.uniacid, "and index:", index);
		  // Trendy
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/qkx7u_PTkkn4j263-OB2F";
		  this.iframeVisible = true;
		} else if (this.uniacid == 22) {
		  // Ho Chun
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/N9JAGmog3UP0ZEvaNasJT";
		  this.iframeVisible = true;
		} else if (this.uniacid == 15) {
		  // Alex Au
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/xM-hfhOrUvRfOMQ19kCsJ";
		  this.iframeVisible = true;
		} 
		else if (this.uniacid == 43) {
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/g8iskMpG-G_M9JdhNrcV7";
		  this.iframeVisible = true;
		} else if (this.uniacid == 24 && index === 0) {
		  // SK Easy and first item in tabBarList3
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/otAB3VzMlW2EmMlr3lNOp";
		  this.iframeVisible = true;
		}
		else if (this.uniacid == 17) {
		
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/zPA1BOaPF3XHAo-JpQrAg";
		  this.iframeVisible = true;
		}
	  },
	  closePopup() {
		this.iframeVisible = false;
		this.iframeUrl = "";
	  },
	  async getAutoLink() {
		var ss = window.localStorage.getItem("webinfo");
		var ssarray = JSON.parse(ss);
		this.webset = ssarray;
		this.uniacid = ssarray['ucid'];
		this.address = ssarray["address"];
		this.email = ssarray["email"];
		this.tel = ssarray["tel"];
		this.qyname = ssarray["qyname"];
		this.whatsapp = ssarray["whatsapp"];
		this.facebook = ssarray["facebook"];
		this.bootbakcolor = ssarray["bootbakcolor"];
		this.opentime = ssarray["opentime"].split(",");
		const res = await this.$http.get("/user/botmenu", {
		  uniacid: this.uniacid,
		});
		this.tabBarList3 = res.data;
		console.log("tabBarList3:", this.tabBarList3);
	  },
	  logItem(item, index) {
		console.log("Item:", item, "Index:", index);
	  },
	},
  };

  </script>
  
 <style lang="scss" scoped>
.footer {
  margin-left: 0px;
  background: #007ea3;
  clear: both;
}
.container {
  margin-top: 50px;
  
  @media (min-width: 320px) {
    margin-left: 0px;
  }
  @media (min-width: 481px) {
    margin-left: 0px;
  }
  @media (min-width: 641px) {
    margin-left: 0px;
  }
  @media (min-width: 961px) {
    margin-left: 0px;
  }
  @media (min-width: 1025px) {
    margin-left: 100px;
  }
  @media (min-width: 1281px) {
    margin-left: 100px;
  }
}
.row {
  display: flex;
  margin-right: 15px;
  margin-left: 15px;
  justify-content: center;
}
.col-md-4 {
  flex: 1;
}
.fo-title {
  color: #fff;
  position: relative;
  padding-bottom: 15px;
  margin-left: 35px;
}
.f1-list {
  padding: 0;
  margin-left: 35px;
  list-style: none;
}
.copy-right {
  background: #016a8a;
  margin-top: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  line-height: 1; /* Set a specific line height */
}

.copyright-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.copyright-text {
  margin: 0;
  padding: 0;
 
}
.text-center {
  text-align: center;
}
.footer-wrapper {
  margin-top: 0px;
  .top {
    height: 104px;
    border-bottom: 1px solid #e2e2e2;
    color: #282828;
    font-size: 14px;
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB",
      "Microsoft YaHei", "微软雅黑, Arial", "sans-serif";
    .items {
      flex: 1;
      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        margin-right: 14px;
      }
    }
  }
  .bottom {
    font-size: 14px;
    margin-top: 30px;
    padding-bottom: 30px;
  }
}
/* Popup Iframe Styles */
.iframe-popup {
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 1000;
}
.iframe-content {
  position: absolute;
  width: 380px;
  height: 500px;
  background: #fff;
}
.close-btn {
  position: absolute;
  top: 28px;
  right: 2px;
  background: #ff0000;
  color: #fff;
  border: none;
  width: 15px;
  cursor: pointer;
}
.co-list {
	margin-left: 35px;
}
@media (max-width: 480px) {
  .close-btn {
    top: 24px;
    right: 2px;
  }
}
iframe {
  width: 100%;
  height: 100%;
}
</style>